<template>
  <div class="creatorPostSave">
    <a :class="`button send ${ready}`" @click="$emit('click')">
      <div v-if="$lockapp" dcolor class="postSpinner">
        <iconic name="gspinner" />
        <span v-if="!progress.message">{{ $locale["creating_your_post"] }}</span>
        <span v-else v-html="`${progress.message} (${number_format(progress.percent)}%)`"></span>
      </div>
      <span v-else>
        <span v-if="type === 'story'">
          <span v-if="mode === 'edit'">{{ $locale["save_story"] }}</span>
          <span>{{ $locale["create_story"] }}</span>
        </span>
        <span v-else>
          <span v-if="mode === 'edit'">{{ $locale["save_post"] }}</span>
          <span v-else>{{ $locale["create_post"] }}</span>
        </span>
      </span>
    </a>
  </div>
</template>

<script>
import CreatorPostNew from "./CreatorPostNew.js";
export default {
  mixins: [CreatorPostNew],
  props: ["ready", "type", "mode"],
};
</script>

<style lang="scss" scoped>
.creatorPostSave {
  .postSpinner {
    color: #fff;
    display: flex;
    align-items: center;
    gap: $mpadding/2;
  }
}
</style>
