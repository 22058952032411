<template>
  <div class="list-item" @click="$emit('click')">
    <div class="list-item-left">
      <div class="list-item-icon" v-if="icon"><iconic :name="icon" /></div>
      <div class="list-item-text" v-html="text"></div>
    </div>
    <div class="list-item-right">
      <input class="mycheck circle primary" type="checkbox" :checked="checked ? true : false" noevents />
    </div>
  </div>
</template>

<script>
export default {
  props: ["icon", "text", "checked"],
};
</script>

<style lang="scss" scoped>
.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: $mpadding/2 $mpadding/2;
  &:hover {
    background-color: #e9e9e9;
    font-weight: bold;
  }
  &-left,
  &-right {
    display: flex;
    align-items: center;
  }
  &-icon {
    @include Flex(inherit, center, center);
    width: $mpadding * 2;
    height: $mpadding * 2;
    min-width: $mpadding * 2;
    background-image: var(--flag);
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    font-size: $mpadding * 2;
  }
  &-icon + &-text {
    margin: 0 0 0 $mpadding;
  }
  &[rejec] {
    .mycheck:checked:before {
      content: "\2613";
    }
  }
}
</style>
