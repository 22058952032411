<template>
  <div class="post-creator main-view" :id="`post-${viewID}`" v-if="$user && $locale" :mode="mode">
    <div class="post-creator-content">
      <div class="post-creator-card view-card">
        <div class="header">
          <div class="title">
            <div v-if="mode === 'create'">
              <span v-if="type === 'story'">{{ $locale["create_story"] }}</span>
              <span v-else>{{ $locale["create_post"] }}</span>
            </div>
            <div v-else-if="mode === 'edit'">
              <span v-if="type === 'story'">{{ $locale["edit_story"] }}</span>
              <span v-else>{{ $locale["edit_post"] }}</span>
            </div>
          </div>
          <div class="options">
            <a class="button close" @click="goBack"><iconic name="times" /></a>
          </div>
        </div>
        <form class="body NewPostBody" id="post-data" v-on:submit.prevent>
          <div class="body-wrap">
            <div class="content">
              <div class="content-user" :style="`--userPicture: url(${$user.picture})`">
                <div class="content-user-left">
                  <figure :class="`content-user-avatar ${$user.picture ? 'src' : 'empty'}`"></figure>
                  <div>
                    <p class="content-user-name" capitalize>{{ $user.name }}</p>
                    <p class="content-user-user">@{{ $user.user }}</p>
                  </div>
                </div>
                <div class="content-user-right">
                  <a class="post-target-btn" @click="$refs.Collapse2.toggle()">
                    <span class="label">{{ availabelLabel }}</span>
                    <span :class="`chevron flex-center`" :style="`${targetPost === 'open' ? 'transform:rotate(180deg)' : ''}`">
                      <iconic name="mf_chevron_down" />
                    </span>
                  </a>
                </div>
              </div>
              <Collapse ref="Collapse2" header="hidden" @open="targetPost = 'open'" @close="targetPost = 'close'">
                <div class="available-for">
                  <ListItemCheck
                    icon="globe-americas"
                    :text="$locale['free']"
                    @click="(availableFor = 'public'), $refs.Collapse2.toggle()"
                    :checked="availableFor === 'public'"
                  />
                  <ListItemCheck
                    icon="user-plus"
                    :text="$locale['premium']"
                    @click="(availableFor = 'subscriber'), $refs.Collapse2.toggle()"
                    :checked="availableFor === 'subscriber'"
                  />
                  <ListItemCheck
                    icon="creditCard"
                    :text="$locale['plus']"
                    @click="(availableFor = 'purchase'), $refs.Collapse2.toggle()"
                    :checked="availableFor === 'purchase'"
                  />
                </div>
              </Collapse>
              <div class="content-message">
                <TextareaRich
                  :class="`NewPostMessageBox`"
                  name="message"
                  :placeholder="$locale['object']['user-post']['post-message-placeholder']"
                  @input="postMessage"
                  @click="$refs.Collapse2.close()"
                  :content="message"
                  @paste.prevent="onPaste"
                />
              </div>
            </div>
            <div class="files NewPostFiles">
              <PostMediaSlider v-if="files && files.length" :key="files && files.length" :isEdit="true" @delSlide="delSlide">
                <div v-for="(file, index) in filesPreview" :key="`file-${index}`" :class="`file file-${file.id}`">
                  <div class="file-media">
                    <img v-if="isFileType(file.name) === 'image'" :src="`${file.src}`" alt="Image" />
                    <video
                      v-else-if="isFileType(file.name) === 'video'"
                      class="homeVideo"
                      width="100%"
                      controls
                      playsinline
                      controlsList="nodownload"
                    >
                      <source :src="`${file.src}`" :type="file.type" />
                    </video>
                  </div>
                </div>
              </PostMediaSlider>
            </div>
            <div class="price" v-if="availableFor === 'purchase'">
              <div class="PricePostBlock">
                <div class="block-input price-input _usd">
                  <label tcenter v-html="`${$locale['price_dollars']} (USD)`"></label>
                  <div class="inputFake">
                    <input tcenter type="number" name="price_usd" v-model="price_usd" placeholder="1" @input="priceInput" />
                    <span class="usdPriceLabel">$ {{ Go.number_format(price_usd, 2) }}</span>
                  </div>
                </div>
                <div class="block-input price-input credits">
                  <label class="postPriceLabel" tcenter>
                    <span>{{ $locale["price_credits"] }}</span>
                    <span dcolor><iconic name="mf_lock" /></span>
                  </label>
                  <div class="inputFake">
                    <input tcenter type="number" name="price" :value="price" placeholder="1" readonly />
                    <span class="usdPriceLabel">
                      <span class="usdPriceLabelIcon"><iconic name="coins" /></span>
                      <span>{{ Go.number_format(price) }}</span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="discount">
                <div class="block-input">
                  <div class="title">
                    <div class="title-left">
                      <span bold>{{ $locale["object"]["user-profile"]["input-discount-title"] }}</span>
                    </div>
                    <div class="title-right">
                      <ToggleSwitchLight name="discountStatus" :value="isDiscount" @change="setDiscount" />
                    </div>
                  </div>
                  <div class="inputs" v-if="isDiscount === 'on'">
                    <div class="label">{{ $locale["object"]["user-post"]["input-discount-label"] }}</div>
                    <div class="couple">
                      <div class="couple-input">
                        <label bold>{{ $locale["object"]["user-profile"]["input-discount-date-label"] }}</label>
                        <input
                          v-calendar="{ date: $global.toInputDate(discountDateLimit), onApply: setDateDiscount }"
                          type="date"
                          name="discountDateLimit"
                          :value="$global.toInputDate(discountDateLimit)"
                          :placeholder="$locale['object']['user-profile']['input-birthday-placeholder']"
                        />
                      </div>
                      <div class="couple-input">
                        <label bold>{{ $locale["object"]["user-profile"]["input-discount-value-label"] }}</label>
                        <InputPrefix :value="discountPercent" name="discountPercent" prefix="%" dir="rtl" :limit="100" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Spacer num="1" v-if="type === 'story'" />
            </div>
          </div>
          <CreatorHistoryTime v-if="type === 'story'" :post="dataPost" />
        </form>
        <div class="tools">
          <label class="tools-content">
            <div class="tools-left">
              <p class="title">
                <span>{{ $locale["object"]["user-post"]["post-tools-title"] }}</span>
                <span v-html="` <b>${files ? files.length : 0}</b> ${$locale['object']['user-post']['post-tools-files-label']}`"></span>
              </p>
            </div>
            <div class="tools-right">
              <label class="button tooltip">
                <iconic name="photo-video" />
                <span class="tooltiptext tool-auto">
                  <p nowrap>{{ $locale["object"]["user-post"]["post-media-type-label"] }}</p>
                </span>
                <input
                  type="file"
                  accept="image/png, image/jpeg, video/mp4, video/mpg"
                  :multiple="type === 'post' ? 'multiple' : false"
                  @change="appendFiles"
                  hide
                  hidden
                />
              </label>
            </div>
          </label>
        </div>
        <div class="footer">
          <CreatorPostNewSave
            :ready="`${(files && files.length) || message || newMessage ? 'ready' : ''}`"
            :loading="loading"
            :type="type"
            @click="sendData('post-data')"
            :mode="mode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextareaRich from "../widgets/TextareaRich.vue";
import Collapse from "../widgets/Collapse.vue";
import ListItemCheck from "../widgets/ListItemCheck.vue";
import PostMediaSlider from "../widgets/PostMediaSlider.vue";
import InputPrefix from "../inputs/InputPrefix.vue";
import ToggleSwitchLight from "../inputs/ToggleSwitchLight.vue";
import CreatorPostNewSave from "./CreatorPostNewSave.vue";
import CreatorPostNew from "./CreatorPostNew.js";
import CreatorHistoryTime from "./CreatorHistoryTime.vue";

export default {
  mixins: [CreatorPostNew],
  components: {
    TextareaRich,
    Collapse,
    ListItemCheck,
    PostMediaSlider,
    InputPrefix,
    ToggleSwitchLight,
    CreatorPostNewSave,
    CreatorHistoryTime,
  },
  data: function() {
    return {
      viewID: this.$global.fastID(),
      message: null,
      newMessage: null,
      files: [],
      filesPreview: [],
      availableFor: "public",
      targetPost: "close",
      isDiscount: "off",
      mode: "init",
      discountDateLimit: null,
      discountPercent: null,
      price: 1,
      price_usd: 1,
      loading: false,
      editType: null,
      dataPost: null,
      axios: {
        timeout: 1000 * 60 * 30,
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    setDiscount: function(value) {
      this.isDiscount = value;
    },
    postMessage: function({ target }) {
      this.newMessage = target.textContent;
    },
    elements: function(el) {
      const elmns = {
        view: document.querySelector(`#post-${this.viewID}`),
        custom: document.querySelector(el),
      };
      return elmns[el] || elmns["custom"];
    },
    goBack: function() {
      this.close(() => {
        this.$router.go(-1);
      });
    },
    activate: function(elemn, timeout, active, _class, callback) {
      setTimeout(() => {
        active ? this.elements(elemn)?.classList.add(_class) : this.elements(elemn)?.classList.remove(_class);
        if (callback) callback();
      }, +timeout);
    },
    open: function(callback) {
      this.activate("body", 0, true, "onpost");
      if (callback) callback();
    },
    close: function(callback) {
      this.activate("body", 0, false, "onpost");
      this.activate("onpost", 10, false, "active", () => {
        if (callback) callback();
      });
    },
    isFileType: function(name) {
      const imgExts = this.imageAllowFormats;
      const vidExts = this.videoAllowFormats;
      if (imgExts.test(name)) {
        return "image";
      } else if (vidExts.test(name)) {
        return "video";
      } else {
        return "unknow";
      }
    },
    appendFiles: function(event) {
      const [files, index] = [event.target.files, 0];
      if (this.type === "post") {
        for (const file of files) {
          const valid = this.postIsValidFile(file);
          if (file.size && valid.success) {
            this.files.push(file);
            this.filesPreview.push({ name: file.name, type: file.type, src: URL.createObjectURL(file), id: this.$global.fastID() });
          } else {
            this.alert(valid);
          }
        }
      } else {
        // If is story only one file
        for (const file of files) {
          const valid = this.postIsValidFile(file);
          if (file.size && valid.success) {
            this.files = [file];
            this.filesPreview = [];
            this.filesPreview.push({ name: file.name, type: file.type, src: URL.createObjectURL(file), id: this.$global.fastID() });
            break;
          } else {
            this.alert(valid);
          }
        }
      }
    },
    delSlide: function(slide) {
      const file = this.filesPreview[slide];
      this.deleteFile(slide, file);
    },
    deleteFile: function(index, item) {
      const fileElement = document.querySelector(`#post-${this.viewID} .files .file-${item.id}`);
      if (fileElement) {
        fileElement?.classList.add("remove");
        setTimeout(() => {
          this.files.splice(index, 1);
          this.filesPreview.splice(index, 1);
        }, 100);
      }
    },
    sendData: function(formID) {
      const messagebox = document.querySelector(`#post-${this.viewID} #${formID} [name="message"]`);
      let postMessage = messagebox.innerHTML.trim();
      let files = this.files;

      if (!postMessage && !files.length) {
        this.ialert.go({ message: this.$locale["object"]["user-post"]["post-missing-data"], unique: true, title: "error" });
        return;
      }

      if (postMessage.length > process.env.VUE_APP_POST_CHAR_LIMIT) {
        this.ialert.go({ message: this.$locale["object"]["user-post"]["post-excess-characters"], unique: true, title: "error" });
        return;
      }

      this.lockapp(true);
      this.$store.dispatch("resetProgress");
      this.$store.dispatch("setProgress", { percent: 0, message: this.$locale["sending_post"] });

      const _data = new FormData(document.getElementById(formID));

      let _message = messagebox.innerHTML.trim();
      _message = encodeURIComponent(_message);

      _data.append("message", _message);
      _data.append("availableFor", this.availableFor);
      _data.append("type", this.type);

      if (files) {
        files.forEach((file, index) => {
          _data.append(`file_${index}`, file);
        });
      }

      let endPoint = `${process.env.VUE_APP_API_HOST}user/post/create`;

      if (this.mode === "edit") {
        endPoint = `${process.env.VUE_APP_API_HOST}user/post/update`;
        _data.append("post", this.$route.params.id);
        this.axios.headers["use-form"] = "true";
      }

      const sedData = this.$api.post(endPoint, _data, this.axios);
      sedData.then((response) => this.sendSuccess(response));
      sedData.catch((response) => this.sendError(response));
    },
    sendSuccess: async function({ data }) {
      if (data.success) {
        this.router("/post/" + data.post._id);
      } else {
        this.ialert.go({ message: data.message, unique: true, title: "Post Error" });
      }
      this.lockapp(false);
    },
    sendError: function(response) {
      if (Go.isError(response, 504)) {
        console.log("WAITING FOR RESPONSE VIA SOCKET");
        return;
      }

      this.ialert.go({ message: response, unique: true, title: "Error" });
      this.lockapp(false);
    },
    getPostData: async function() {
      this.loading = true;
      const postId = this.$route.params.id;
      const _host = `${process.env.VUE_APP_API_HOST}posts?&post=${postId}`;
      const response = await this.$api.get(`${_host}`);
      const post = response.data.posts[0];
      this.dataPost = post;
      this.files = post.media;
      this.filesPreview = post.media;
      this.message = decodeURIComponent(post.message);
      this.availableFor = post.availableFor;
      this.isDiscount = post.discountStatus;
      this.discountPercent = post.discountPercent;
      this.discountDateLimit = post.discountDateLimit;
      this.price = post.price;
      this.editType = post.type;
      this.loading = false;
    },
    initPost: function() {
      this.open();
      const postId = this.$route.params.id;
      if (postId) {
        this.getPostData();
        this.mode = "edit";
      } else {
        this.mode = "create";
      }
    },
    setDateDiscount: function(date) {
      this.discountDateLimit = date;
    },
  },
  computed: {
    type: function() {
      return this.$route.query.type || this.editType || "post";
    },
    availabelLabel: function() {
      const mtx = {
        public: this.$locale["free"],
        subscriber: this.$locale["premium"],
        purchase: this.$locale["plus"],
      };
      return mtx[this.availableFor];
    },
  },
  mounted: async function() {
    this.initPost();
  },
  beforeRouteLeave(to, from, next) {
    this.close(() => {
      next();
    });
  },
};
</script>

<style lang="scss">
$avatar_size: 42px;
body.onpost {
  .footer-nav {
    display: none;
  }
}
.post-creator {
  @include Fixed();
  z-index: 11;
  &-content {
    @include Fixed();
    @include Flex(column, center, center);
    background-color: var(--bg-overlay);
  }
  &-card {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 0;
    max-height: 100%;
    overflow: hidden;
    .header {
      @include Flex(inherit, space-between, center);
      padding: 0 0 0 $mpadding;
      border-bottom: solid 1px $alto;
      height: $header_height;
      .title {
        font-weight: bold;
        font-size: 120%;
      }
      .options {
        .button {
          @include Flex(inherit, center, center);
          cursor: pointer;
          .iconic {
            height: $header_height;
            border-radius: 50%;
            padding: $mpadding;
          }
          &:hover {
            color: $primary_color;
          }
        }
      }
    }
    .body {
      max-height: calc(100% - 185px);
      overflow-y: auto;
      .content {
        padding: $mpadding;
        &-user {
          @include Flex(inherit, space-between, center);
          padding: 0 0 $mpadding/2 0;
          &-left,
          &-right {
            @include Flex(inherit, center, center);
          }
          &-avatar {
            width: $avatar_size;
            height: $avatar_size;
            @include BgImage();
            background-image: var(--userPicture);
            border-radius: 50%;
            margin: 0 $mpadding/1.5 0 0;
            border: solid 2px $primary_color;
          }
          &-name {
            font-weight: bold;
          }
          &-user {
            color: $emperor;
          }
          .post-target-btn {
            @include Flex(inherit, center, center);
            background-color: $primary_color;
            color: #fff;
            padding: $mpadding/4 $mpadding/2;
            border-radius: $mpadding/2;
            cursor: pointer;
            user-select: none;
          }
        }
        &-message {
          .textarea-rich-box {
            padding: 0;
            border: none;
            min-height: $mpadding * 2;
            background-color: rgba(9, 30, 66, 0.01);
            border: solid 1px $alto;
            padding: $mpadding;
          }
        }
      }
      .files {
        display: block;
        .postMediaSlider {
          margin: 0 0 $mpadding 0;
        }
        .file {
          position: relative;
          float: left;
          width: 100%;
          overflow: hidden;
          transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
          transform: scale(1);
          min-height: $mpadding * 3.5;
          &.remove {
            transform: scale(0);
          }
          &.image {
            background-image: var(--file);
            background-size: cover;
            background-position: center;
          }
        }
        img,
        video {
          max-width: 100%;
        }
      }
    }
    .available-for {
      padding: $mpadding/2;
      margin: 0 0 $mpadding 0;
      border: solid 1px $alto;
      border-radius: $mpadding/2;
      .list-item {
        border-radius: $mpadding/2;
      }
      .list-item-icon {
        font-size: 130%;
      }
    }
    .price {
      padding: 0 $mpadding 0 $mpadding;
      .block-input {
        input {
          text-align: center;
          font-weight: bold;
        }
        &.price-input {
          margin-top: 0;
        }
      }
      .discount {
        padding: 0 $mpadding 0 $mpadding;
        border: solid 1px $alto;
        border-radius: $mpadding/2;
        margin: $mpadding 0 0 0;
        .title {
          @include Flex(inherit, space-between, center);
          padding: 0 0 $mpadding 0;
        }
        .label {
          text-align: center;
        }
        .inputs {
          border-top: solid 1px $alto;
          padding: $mpadding 0 $mpadding 0;
        }
      }
    }
    .tools {
      padding: $mpadding $mpadding 0 $mpadding;
      &-content {
        @include Flex(inherit, space-between, center);
        padding: $mpadding/2 $mpadding;
        border: solid 1px $alto;
        border-radius: $mpadding/2;
        .button {
          @include Flex(inherit, center, center);
          position: relative;
          width: $mpadding * 2.5;
          height: $mpadding * 2.5;
          cursor: pointer;
          border-radius: 50%;
          .iconic {
            font-size: 150%;
            color: $primary_color;
          }
          &:hover {
            background-color: $alto;
          }
        }
      }
    }
    .footer {
      padding: $mpadding;
      .button {
        @include Flex(inherit, center, center);
        cursor: pointer;
        padding: $mpadding/1.5 $mpadding;
        border-radius: $mpadding/2;
        background-color: $alto;
        user-select: none;
        pointer-events: none;
        cursor: no-drop;
        &.ready {
          pointer-events: all;
          background-color: $primary_color;
          color: #fff;
          cursor: pointer;
          &:active {
            @include d-active();
          }
        }
      }
    }
  }

  &[mode="edit"] {
    .postMediaSlider-info {
      .button.delete {
        display: none;
      }
      .button.count {
        border-right: none;
      }
    }
    .tools-content {
      user-select: none;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .PricePostBlock input[name="price"] {
    background-color: $alto;
  }

  @media (min-width: $tablet_width) {
    &-content {
      padding: $mpadding;
    }
    &-card {
      border-radius: $mradius;
      height: auto;
      max-width: 500px;
    }
  }
}
</style>
