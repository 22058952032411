<template>
  <div class="CreatorHistoryTime" :title="`${$locale['story']} ${$locale['duration']}`">
    <div class="CreatorHistoryTimeContent">
      <Tabs :tabs="tabs" @tab="(tab) => (current = tab.value)" />
      <input type="hidden" name="duration" :value="current" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["post"],
  data: function() {
    return {
      time: 0,
      current: this.post && this.post.duration ? this.post.duration : 24,
    };
  },
  computed: {
    tabs: function() {
      return [
        {
          title: `24 ${this.$locale["hours"]}`,
          value: 24,
          selected: this.current === 24,
        },
        {
          title: `48 ${this.$locale["hours"]}`,
          value: 48,
          selected: this.current === 48,
        },
        {
          title: `72 ${this.$locale["hours"]}`,
          value: 72,
          selected: this.current === 72,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.CreatorHistoryTime {
  position: relative;
  padding: 0 $mpadding;

  .CreatorHistoryTimeContent {
    position: relative;
    background-color: rgba($color: $alto, $alpha: 0.17);
    border: solid 1px $alto;
    border-radius: $mpadding/2;
    overflow: hidden;
    z-index: 2;

    .tabs-tab {
      font-family: $third_font_bold;
    }

    .tabs-tab.selected {
      background-color: $primary_color;
      color: #fff;
    }
  }
}
</style>
