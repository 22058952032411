const NewPost = Go.vue;

export default NewPost;

NewPost.data = function() {
  return {
    price_usd: 0,
  };
};

NewPost.computed.creadis_val = function() {
  return Number(this.$locale["credits_value"]) - Number(this.$locale["ptfm_fee"]);
};

NewPost.computed.progress = function() {
  return this.$store.state.progress;
};

NewPost.methods.onPaste = function(e) {
  e.preventDefault();
  const text = (e.clipboardData || window.clipboardData).getData("text/plain");
  document.execCommand("insertHTML", false, text);
};

NewPost.methods.priceInput = function(e) {
  this.price_usd = e.target.value;
  this.price = Go.number_format(this.price_usd / this.creadis_val);
};

NewPost.mounted = function() {
  this.price = Go.number_format(this.price / this.creadis_val);
};

NewPost.computed.videoAllowFormats = function() {
  const formats = /(\.(mp4|mpg|avi|mov|wmv|flv|mkv|webm))$/i;
  return formats;
};

NewPost.computed.imageAllowFormats = function() {
  const formats = /(\.(jpg|jpeg|png|gif|svg))$/i;
  return formats;
};
